<template>
    <admin-dashboard-layout>
        <v-card>
            <v-card-title>
                <v-row>
                    <v-col>
                        {{ lead.name }}
                    </v-col>
                    <v-col class="text-right">
                        <v-btn text :to="{name: 'admin.leads.index'}">return</v-btn>
                    </v-col>
                </v-row>
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-row>
                        <v-col>
                            <v-text-field label="Name" placeholder="Please enter a name" outlined v-model="lead.name" ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="User:" v-if="lead.user" v-model="lead.user.name" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Partner id:" v-if="lead.partner" v-model="lead.partner.name" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Developer:" v-if="lead.developer" v-model="lead.developer.name" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Bank:" v-if="lead.bank" v-model="lead.bank.name" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="E-mail:" v-model="lead.email" disabled></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field label="Phone" v-model="lead.phone" disabled></v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
    </admin-dashboard-layout>
</template>

<script>
import Lead from "../../../models/Lead";
import AdminDashboardLayout from "../../../layouts/AdminDashboardLayout";

export default {
    name: "leads-show",
    components: {AdminDashboardLayout},
    data: function () {
        return {
            lead: {},
            mortgages: false
        }
    },
    async mounted() {
        const leadId = this.$route.params.id
        this.lead = await Lead.find(leadId);
        console.log(this.lead,'vvvv');

    },
}
</script>

<style scoped>

</style>
